<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" :title="dialogTitle" width="1000px">
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="120px">
        <el-form-item label="目标名称" prop="target_type">
          <el-select v-model="formData.target_type" placeholder="请选择目标名称" clearable :style="{width: '100%'}"
                     :disabled="formData.target_type===1&&formData.id>0">
            <el-option v-for="(item, index) in target_typeOptions" :key="index" :label="item.label"
                       :value="item.value" :disabled="item.value===1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标大项" prop="target_term">
          <el-input v-model="formData.target_term" placeholder="请输入目标大项" clearable :style="{width: '100%'}" maxlength="100" show-word-limit>
          </el-input>
        </el-form-item>
        <!--        <el-form-item label="目标描述" prop="target_dsp">-->
        <!--          <el-input v-model="formData.target_dsp" placeholder="请输入目标描述" clearable :style="{width: '100%'}">-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="权重(下限)" prop="lower_w">
          <el-input-number v-model="formData.lower_w" :step="10" placeholder="权重(下限)"></el-input-number>
        </el-form-item>
        <el-form-item label="权重(上限)" prop="upper_w">
          <el-input-number v-model="formData.upper_w" :step="10" placeholder="权重(上限)"></el-input-number>
        </el-form-item>
        <el-form-item label="核算权重" prop="real_w">
          <el-input-number v-model="formData.real_w" :step="10" placeholder="核算权重"></el-input-number>
        </el-form-item>
        <!--        <el-form-item label="完成情况一览" prop="real_intro">-->
        <!--          <el-input v-model="formData.real_intro" placeholder="请输入完成情况一览" clearable :style="{width: '100%'}">-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="目标描述">
          <!--        目标描述/完成情况一览-->
          <TargetItems ref="refTargetItems" @currentItems="currentItems" @delItem="delItem"
                       :target-type="formData.target_type" :data="formData.items"/>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import TargetItems from './TargetItems'

export default {
  name: "CreateEdit",
  inheritAttrs: false,
  components: { TargetItems },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          dept_id: null,
          year: null,
          year_half: null
        }
      }
    },
    editObj: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      formData: {
        target_type: 2,
        target_term: '',
        lower_w: 30,
        upper_w: 60,
        real_w: 0,
        items: [
          { target_dsp: '', real_intro: '', }
        ],
        del_items: []
      },
      rules: {
        target_type: [{
          required: true,
          message: '请选择目标名称',
          trigger: 'change'
        }],
        target_term: [{
          required: true,
          message: '请输入目标大项',
          trigger: 'blur'
        }],
        target_dsp: [{
          required: true,
          message: '请输入目标描述',
          trigger: 'blur'
        }],
        lower_w: [{
          required: true,
          message: '权重(下限)',
          trigger: 'blur'
        }],
        upper_w: [{
          required: true,
          message: '权重(上限)',
          trigger: 'blur'
        }],
        real_w: [{
          required: false,
          message: '核算权重',
          trigger: 'blur'
        }],
        real_intro: [{
          required: false,
          message: '请输入完成情况一览',
          trigger: 'blur'
        }],
      },
      target_typeOptions: [{
        "label": "部门总营收（固定目标）",
        "value": 1
      }, {
        "label": "自定义目标",
        "value": 2
      }],
    }
  },
  computed: {
    dialogTitle() {
      if (this.editObj.info_id) {
        return `编辑-部门总监绩效：${ this.searchCondition.dept_name } ${ this.searchCondition.year }年 ${ this.searchCondition.year_half_alias }(ID:${ this.editObj.info_id })`
      } else {
        return `添加-部门总监绩效：${ this.searchCondition.dept_name } ${ this.searchCondition.year }年 ${ this.searchCondition.year_half_alias }`
      }
    }
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    onOpen() {
      this.formData.id = null
      this.formData.items = []
      this.formData.del_items = []
      this.getInfo()
    },
    onClose() {
      this.$refs['elForm'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        //保存
        this.saveInfo()
      })
    },
    async saveInfo() {
      if (this.$refs['refTargetItems'])
        this.formData.items = this.$refs['refTargetItems'].getCurrentItems()

      let postData = { ...this.formData, ...this.searchCondition };
      let id = await this.$api.savePerfDeptInfo(postData)
      if (id) {
        this.$notify.success('保存成功')
        this.close()
      }
    },
    async getInfo() {
      if (this.editObj.info_id) {
        let { info } = await this.$api.getPerfDeptInfo(this.editObj.info_id)
        if (info) {
          this.formData = info
          if (!this.formData.items) {
            this.formData.items = []
          }
        }
      }
    },
    //添加项目描述
    // addItem(val) {
    //   if (!this.formData.items) {
    //     this.formData.items = []
    //   }
    //
    //   this.formData.items.push(val)
    //   console.log('this.formData', this.formData)
    // },
    currentItems(val) {
      this.formData.items = val
      console.log('this.formData', this.formData)
    },
    delItem(val) {
      if (!this.formData.del_items) {
        this.formData.del_items = []
      }
      this.formData.del_items.push(val.id)
      console.log('del_items', this.formData.del_items)
    }
  }
}

</script>
<style>
</style>
