<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" @opened="onOpened" @close="onClose" :title="dialogTitle" width="75%">
    <el-row v-if="!this.searchCondition.info_id">
      <el-col :span="24">
        <el-form :inline="true">
          <el-form-item>
            <el-input v-model="searchCondition.keyword" placeholder="关键字" @change="search" clearable/>
          </el-form-item>
          <el-form-item label="类型：">
            <el-select v-model="searchCondition.op_type" @change="search" clearable>
              <el-option label="添加" value="init">初始化</el-option>
              <el-option label="添加" value="add">添加</el-option>
              <el-option label="修改" value="edit">修改</el-option>
              <el-option label="删除" value="del">删除</el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search"><i class="el-icon-search"></i>搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <ApeTable ref="apeTable" :data="dataList" :columns="columns" :loading="loadingStatus" :pagingData="pagingData"
              @switchPaging="switchPaging" highlight-current-row>
    </ApeTable>
  </el-dialog>
</template>

<script>
import ApeTable from "@/components/ApeTable";

export default {
  name: "OpLog",
  inheritAttrs: false,
  components: {
    ApeTable
  },
  props: {
    condition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      columns: [
        {
          title: '部门',
          value: 'dept_name',
          width: 100,
          header_align: 'center',
          align: 'center'
        },
        {
          title: '日志内容',
          value: 'msg',
          width: 400,
          header_align: 'center',
          align: 'left'
        },
        {
          title: '操作',
          value: 'op_type_alias',
          width: 100,
          header_align: 'center',
          align: 'center'
        },
        {
          title: '操作时间',
          value: 'created_at',
          width: 120,
          header_align: 'center',
          align: 'center'
        },
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0,
      },
      searchCondition: {
        op_type: null, info_id: null, keyword: null, dept_id: null,
        year: null,
        year_half: null,
      }
    }
  },
  computed: {
    dialogTitle() {
      return `操作日志-${ this.condition.dept_name } / ${ this.condition.year }年 / ${ this.condition.year_half === 'first_half' ? '上半年' : '下半年' } `;
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    search() {
      this.$refs['apeTable'].handleCurrentChange(1)
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    async initList(type) {
      this.loadingStatus = true
      this.searchCondition = { ...this.searchCondition, ...this.condition }
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, pagingInfo)
      let { list, pages } = await this.$api.getPerfDeptInfoLog(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total

      this.pagingData.offset = pages.offset
      this.loadingStatus = false
    },
    handleSearchCondition() {
      let condition = {}
      this.conditionStr = '';

      if (this.searchCondition.dept_id) {
        condition.dept_id = this.searchCondition.dept_id
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.year_half) {
        condition.year_half = this.searchCondition.year_half
      }
      if (this.searchCondition.info_id) {
        condition.info_id = this.searchCondition.info_id
      }
      if (this.searchCondition.op_type) {
        condition.op_type = this.searchCondition.op_type
      }
      if (this.searchCondition.keyword) {
        condition.keyword = this.searchCondition.keyword
      }

      return condition
    },
    onOpened() {
      this.switchPaging()
    },
    onClose() {
      this.dataList = []
      // this.$refs['elForm'].resetFields()
    },
  }
}

</script>

<style scoped>

</style>
