<template>
  <div>
    <el-select v-bind="$attrs" v-on="$listeners" :placeholder="`部门选择`" filterable
               @change="selectDept" style="width: 100%" clearable>
      <el-option :label="`${item.name}`"
                 :value="item.dpt_id"
                 v-for="item in options"
                 :key="item.dpt_id"
                 :disabled="item.disabled">
        <!--        <span style="float: left">{{ item.name }}</span>-->
        <!--        <span style="float: right">{{ item.dpt_id }}</span>-->
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "DeptSelect",
  props: {
    showFirstGroup: {
      type: Boolean,
      default() {
        return false
      }
    },
    currentDeptId: {
      type: [Number, String],
      default() {
        return null;
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    type: {
      type: String,
      default: ""//revenue_data revenue_enter trend_data trend_enter performance_dept
    }
  },
  data() {
    return {
      value: null,
      dpt_id: null,
      options: [],
      scopeGroups: [],
      range: { dpt_range: [], my_dpt_range: [] },
    };
  },
  watch: {
    currentDeptId(val) {
      this.value = val
    },
  },
  methods: {
    async getOptions() {
      this.options = []
      let { list } = await this.$api.getMcnGroupDeptOption(this.type)
      this.$nextTick(() => {
        this.options = list;
        if (this.showFirstGroup) {
          this.firstGroup()
        }
      })
    },
    firstGroup() {
      if (this.options.length > 0) {
        if (this.options[0]) {
          let dept = this.options[0] ? this.options[0] : {}
          this.$emit('selectDept', { dept_id: dept.dpt_id, dept_name: dept.name })
        }
      }
    },
    selectDept(val) {
      let dept = this.options.find((item) => item.dpt_id === val)
      //后端部门ID（dpt_id）和前端命名不同（dept_id）
      this.$emit('selectDept', { dept_id: dept.dpt_id, dept_name: dept.name })
    },
  },
  mounted() {
    this.getOptions()
  }
}
</script>

<style scoped>

</style>
