<template>
  <div>
    <span class="title">绩效补充说明：{{ dialogTitle }}</span>
    <div v-if="userPermissions.indexOf('perf_dept_save')>-1"  style="display: inline">
      <el-link class="supplement-note" @click="editNote" >
        <i :class="`${readonly?'el-icon-edit':'el-icon-check'}`" style="font-size: 1rem"></i>
        {{ readonly ? '修改' : '保存' }}
      </el-link>
      <el-link v-if="!readonly" @click="cancelSave"><i class="el-icon-close"
                                                       style="font-size: 1rem;margin-left: 10px"></i>取消
      </el-link>
    </div>

    <el-input type="textarea" :rows="8" placeholder="绩效补充说明" v-model="note" :readonly="readonly"></el-input>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PerformanceNote",
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          dept_id: null,
          year: null,
          year_half: null
        }
      }
    },
  },
  watch: {
    searchCondition: {
      deep: true,
      handler(val) {
        // console.log('val',val)
        this.getNote(val)
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `${ this.searchCondition.dept_name } ${ this.searchCondition.year }年 ${ this.searchCondition.year_half_alias }`
    }
  },
  data() {
    return {
      //补充说明
      note: '',
      readonly: true
    }
  },
  methods: {
    editNote() {
      this.readonly = !this.readonly
      if (this.readonly) {
        this.saveNote()
      }
    },
    cancelSave() {
      this.readonly = true
      this.getNote()
    },
    // 补充说明
    async saveNote() {
      let data = { ...this.searchCondition }
      data['note'] = this.note
      let id = await this.$api.savePerfDeptNote(data)
      if (id) {
        this.$notify.success('保存成功')
      }
    },
    async getNote() {
      if (this.searchCondition.dept_id && this.searchCondition.year && this.searchCondition.year_half) {
        let { info } = await this.$api.getPerfDeptNote(this.searchCondition)
        if (info) {
          this.note = info.note
        }
      }

    }
  },
  mounted() {
    this.getNote()
  }
}
</script>

<style scoped>
.title {
  font-weight: 600;
}
</style>
