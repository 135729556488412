<template>
  <div>
    <el-form :inline="true" :model="formData">
      <!--      <el-form-item label="" prop="target_dsp">-->
      <!--        <el-input v-model="formData.target_dsp" placeholder="请输入目标描述" clearable :style="{width: '100%'}">-->
      <!--        </el-input>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="完成情况一览" prop="real_intro">-->
      <!--        <el-input v-model="formData.real_intro" placeholder="请输入完成情况" clearable :style="{width: '100%'}">-->
      <!--        </el-input>-->
      <!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd"
                    circle></el-button>
      </el-form-item>
    </el-form>
    <div class="default-table">
      <el-table :data="tableData" border>
        <!--        <el-table-column prop="id" label="数据ID" width="100" align="center"></el-table-column>-->
        <el-table-column prop="target_dsp" label="项目描述" min-width="300" align="center">
          <editable-cell
              :show-input="row.editMode"
              slot-scope="{$index,row}"
              v-model="row.target_dsp"
              :is-input-number="false" :is-selected="false" maxlength="200" show-word-limit>
                          <span slot="content">
                            <span>{{ row.target_dsp }}</span>
                            <i class="el-icon-edit default-edit-icon"></i>
                          </span>
          </editable-cell>
        </el-table-column>
        <el-table-column prop="real_intro" label="完成情况" min-width="120" align="center">
          <editable-cell
              :show-input="row.editMode"
              slot-scope="{$index,row}"
              v-model="row.real_intro"
              :is-input-number="false" :is-selected="true" maxlength="200"
              show-word-limit>
                          <span slot="content">
                            <span>{{ row.real_intro }}</span>
                            <i class="el-icon-edit default-edit-icon"></i>
                          </span>
          </editable-cell>
<!--          <template slot-scope="{row}" v-else>-->
<!--            <span>{{ row.real_intro }}</span>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-link
                type="danger"

                @click="handleDelete(scope.$index, scope.row.id)"
            ><i class="el-icon-close"></i>移除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import EditableCell from "@/components/EditableCell";

export default {
  name: "TargetItems",
  components: {
    EditableCell,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    targetType: {
      type: [Number, Boolean],
      default() {
        return null;
      }
    }
  },
  watch: {
    data(val) {
      this.tableData = [...val];
    }
  },
  data() {
    return {
      formData: { target_dsp: '', real_intro: '', editMode: false },
      tableData: [],
    }
  },
  computed: {
    // tableData() {
    //   return [...this.data]
    // },
    disabledAdd() {
      return this.targetType === 1 && this.tableData.length > 0;
      // 胡杨反馈调整：部门总营收改为可编辑20210112（同步数据不准确）
      // return  this.tableData.length > 0;
    }
  },
  methods: {
    handleAdd() {
      console.log(`目标描述：${ this.formData.target_dsp },完成情况：${ this.formData.real_intro }`)
      this.tableData.push({ ...this.formData })
      this.$emit('currentItems', this.tableData)
    },
    handleDelete(index) {
      let delRows = this.tableData.splice(index, 1);
      if (delRows[0].id)
        this.$emit('delItem', delRows[0])
    },
    //获取当前明细
    getCurrentItems() {
      return this.tableData
    }
  }
}
</script>

<style scoped>

</style>
