<template>
  <div>
    <div class="searchCondition">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" style="display: inline-block;padding-bottom: 5px">
            <el-form-item label="">
              <DeptSelect :type="`performance_dept`" :value="searchCondition.dept_id" @selectDept="selectDept"
                          :show-first-group="true"/>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                  v-model="searchCondition.year"
                  type="year"
                  format="yyyy"
                  value-format="yyyy"
                  placeholder="选择年" @change="initList" clearable>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchCondition.year_half">
                <el-option
                    v-for="item in options"
                    placeholder="选择半年度"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="initList" icon="el-icon-search">搜索</el-button>
              <el-link class="op-link" type="primary" @click="showRowLog"
                       v-if="userPermissions.indexOf('perf_dept_log') !== -1">
                日志
                <i class="el-icon-document"></i></el-link>

            </el-form-item>

            <!--            <el-form-item v-if="userPermissions.indexOf('perf_dept_save')>-1">-->
            <!--              <el-button type="primary" @click="addBtn" icon="el-icon-plus" :disabled="!canAddFlag"-->
            <!--                         v-if="dataList.length>0">新增-->
            <!--              </el-button>-->
            <!--              <el-button type="warning" @click="initDeptInfo" icon="el-icon-refresh" v-else>初始化</el-button>-->
            <!--            </el-form-item>-->
          </el-form>
        </el-col>
        <el-col :span="4" style="text-align: right">
          <export-link ref="refExportLink" :lint-title="`导出表格`"
                       :can-export="userPermissions.indexOf('perf_dept_export') !== -1"
                       @export="exportData"/>
        </el-col>
      </el-row>

    </div>
    <!--    总监目标-->
    <div class="performance-dept default-table">
      <el-table :data="dataList" :span-method="objectSpanMethod" @row-contextmenu="openMenu" border>
        <el-table-column prop="target_name" label="目标名称" align="center" min-width="120"></el-table-column>
        <el-table-column prop="target_term" label="目标大项" align="center" min-width="100"></el-table-column>
        <el-table-column prop="target_dsp" label="目标描述" align="left" header-align="center"
                         min-width="240"></el-table-column>
        <el-table-column prop="lower_w" label="权重（下限）" align="center" min-width="100">
          <template slot-scope="{$index,row}">
            <span v-if="$index===dataList.length-1">
              <span style="font-weight: 700;" class="rate">{{ maxLowerW }}</span>
              <span v-if="row.lower_w!==maxLowerW" style="color: red;font-weight: 700;font-size: 14px">
                (<span class="rate">{{ row.lower_w }}</span>)
              </span>
            </span>
            <span v-else :class="`${row.lower_w?'rate':''}`">{{ row.lower_w }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="upper_w" label="权重（上限）" align="center" min-width="100">
          <template slot-scope="{$index,row}">
            <span v-if="$index===dataList.length-1">
              <span style="font-weight: 700;" class="rate">{{ maxUpperW }}</span>
              <span v-if="row.upper_w!==maxUpperW" style="color: red;font-weight: 700;font-size: 14px">
                (<span class="rate">{{ row.upper_w }}</span>)
              </span>
            </span>
            <span v-else :class="`${row.upper_w?'rate':''}`">{{ row.upper_w }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="real_w" label="核算权重" align="center" min-width="100">
          <template slot-scope="scope">
            <span class="rate" v-if="scope.row.real_w">{{ scope.row.real_w }}</span>
            <span v-else>{{ scope.row.real_w }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="real_intro" label="完成情况一览" align="center" min-width="160"></el-table-column>
      </el-table>
    </div>
    <!--    目标制定注意事项：-->
    <div class="tips">
      <Tips :data="tipList" :title="`目标制定注意事项：`"></Tips>
    </div>
    <div style="width: 40%">
      <!--      绩效补充说明-->
      <PerformanceNote :search-condition="searchCondition"/>
    </div>
    <!--    步骤备注：-->
    <div class="tips">
      <Tips :data="stepList" :title="`备注：`"></Tips>
    </div>

    <!--    新增-->
    <CreateEdit :visible.sync="dialogVisible" :search-condition="searchCondition" :edit-obj="editObj"/>
    <!--     编辑、添加目标描述-->
    <Contextmenu :menu-data="menuData" @change="selectedMenu"/>

    <OpLog :visible="logSetting.visible" @close="closeLog" :condition="searchCondition"/>
  </div>
</template>

<script>
import DeptSelect from '@/pages/departments/DeptSelect'
import CreateEdit from './CreateEdit'
import Contextmenu from './Contextmenu'
import PerformanceNote from './PerformanceNote'
import OpLog from './OpLog'
import { mapGetters } from "vuex";
import ExportLink from "@/components/export/ExportLink";

export default {
  name: "Index",
  components: {
    DeptSelect, CreateEdit, Contextmenu, PerformanceNote, ExportLink, OpLog
  },
  data() {
    return {
      searchCondition: {
        dept_id: null,
        dept_name: null,
        year: null,
        year_half: null,
        year_half_alias: null,
      },
      options: [{
        value: 'first_half',
        label: '上半年（1月-6月）'
      }, {
        value: 'second_half',
        label: '下半年（7月-12月）'
      }],
      tipList: [
        '1、半年度目标综合完成权重值＜60%，无奖金；',
        '2、半年度总营收目标：完成度＜80%权重计算为0；80%≤完成度＜90%权重计算为30%；90%≤完成度＜100%权重计算为40%；100%≤完成度＜120%权重计算为50%；完成度≥120%权重计算为60%；',
        '3、各项目标权重分配时，下限之和必须为60%，上限之和必须为150%；',
        '4、目标描述须尽量做到清晰、简洁、可衡量与评估，不可衡量与评估的目标描述将增加核算权重判断的公正性和准确性；'
      ],
      stepList:[
          '1、默认展示有权限的第一个部门，本半年度数据',
          '2、数据录入：1）录入权限 2）数据为空，则提示初始化，初始化数据为固定目标，不可取消 3）自动同步本部门营收金额（数据来自仪表盘-组织情况一览表） 4）初始化固定目标后，右键菜单录入自定义目标 ',
          '3、总监绩效的编辑权限为VP及以上员工',
          '4、总监绩效的查阅权限为本部总监，本部总监可查阅本部总监绩效，不可查阅其他部门总监绩效'
      ],
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0,
        currentRow: {},
        currentColumn: {}
      },
      mergeFields: ['target_name', 'target_term', 'lower_w', 'upper_w', 'real_w'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      dataList: [],
      dataDemo: [
        {
          target_name: '部门总营收（固定目标）',
          target_type: 1,//固定目标
          target_term: '部门总营收',
          target_dsp: '半年度总营收≥5000万',
          lower_w: 30,
          upper_w: 60,
          real_w: '',
          real_intro: ''
        },
        // {
        //   target_name: '自定义目标',
        //   target_type: 2,//追加目标
        //   target_term: '抖音带货案例',
        //   target_dsp: '每完成一条10万带货案例+5%',
        //   lower_w: '20',
        //   upper_w: '60',
        //   real_w: '',
        //   real_intro: ''
        // },
        // {
        //   target_name: '自定义目标',
        //   target_type: 2,//追加目标
        //   target_term: '抖音带货案例',
        //   target_dsp: '每完成一条25万带货案例/直播单场单品销售额30万+10%',
        //   lower_w: '20',
        //   upper_w: '60',
        //   real_w: '',
        //   real_intro: ''
        // },
        // {
        //   target_name: '自定义目标',
        //   target_type: 2,//追加目标
        //   target_term: '抖音带货案例',
        //   target_dsp: '每完成一条50万带货案例/直播单场单品销售额60万+15%',
        //   lower_w: '20',
        //   upper_w: '60',
        //   real_w: '',
        //   real_intro: ''
        // }, {
        //   target_name: '自定义目标',
        //   target_type: 2,//追加目标
        //   target_term: '抖音带货案例',
        //   target_dsp: '每完成一条100万带货案例/直播单场单品销售额120万+25%',
        //   lower_w: '20',
        //   upper_w: '60',
        //   real_w: '',
        //   real_intro: ''
        // },
        // {
        //   target_name: '自定义目标',
        //   target_type: 2,//追加目标
        //   target_term: '抖音红人发展',
        //   target_dsp: '单红人抖音单平台月营收≥15万的红人，每一个+5%',
        //   lower_w: '',
        //   upper_w: '30',
        //   real_w: '',
        //   real_intro: ''
        // },
        // {
        //   target_name: '自定义目标',
        //   target_type: 2,//追加目标
        //   target_term: '抖音红人发展',
        //   target_dsp: '单红人抖音单平台月营收≥50万的红人，每一人+10%',
        //   lower_w: '',
        //   upper_w: '30',
        //   real_w: '',
        //   real_intro: ''
        // },
        // {
        //   target_name: '自定义目标',
        //   target_type: 2,//追加目标
        //   target_term: '抖音红人发展',
        //   target_dsp: '单红人抖音单平台月营收≥100万的红人，每一人+20%',
        //   lower_w: '',
        //   upper_w: '30',
        //   real_w: '',
        //   real_intro: ''
        // }, {
        //   target_name: '自定义目标',
        //   target_type: 2,//追加目标
        //   target_term: '抖音红人发展',
        //   target_dsp: '单红人抖音单平台月营收≥200万的红人，每一人+30%',
        //   lower_w: '',
        //   upper_w: '30',
        //   real_w: '',
        //   real_intro: ''
        // },
        // {
        //   target_name: '综合完成权重值',
        //   target_type: 'calc',//合计行
        //   target_term: '综合完成权重值',
        //   target_dsp: '综合完成权重值',
        //   lower_w: '60',
        //   upper_w: '150',
        //   real_w: '',
        //   real_intro: ''
        // },
      ],//数据列表

      dialogVisible: false,//弹窗显示
      editObj: {},//编辑对象
      maxLowerW: 60,//综合完成权重值-最大下限
      maxUpperW: 150,//综合完成权重值-最大上限
      logSetting: {
        visible: false,
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    //可新增
    canAddFlag() {
      return this.searchCondition.dept_id && this.searchCondition.year && this.searchCondition.year_half
    }
  },
  watch: {
    'searchCondition.year_half'() {
      let yearOption = this.options.find((item) => item.value === this.searchCondition.year_half)
      this.searchCondition.year_half_alias = yearOption.label
      this.initList()
    },
    dialogVisible(val) {
      if (!val) {
        this.initList()
      }
    }
  },
  methods: {
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.dataList.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][field] === this.dataList[index - 1][field]) {
              // this.spanObj[field][this.positionObj[field]] += 1
              // this.spanObj[field].push(0)

              if (field && ['lower_w', 'upper_w', 'real_w'].indexOf(field) > -1) {
                //平台列，特殊处理
                if (this.dataList[index]['target_term'] === this.dataList[index - 1]['target_term']) {
                  this.spanObj[field][this.positionObj[field]] += 1
                  this.spanObj[field].push(0)
                } else {
                  this.spanObj[field].push(1)
                  this.positionObj[field] = index
                }
              } else {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              }

            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //末尾查询一行合计：
      if (rowIndex === this.dataList.length - 1) {
        //前三列合并
        if (columnIndex < 3) {
          if ((columnIndex + 1) === 3) {
            return [1, 3];
          } else {
            return [0, 0]
          }
        } else {
          return [1, 1];
        }
      } else {
        const fields = this.mergeFields
        if (column.property && fields.indexOf(column.property) > -1) {
          const _row = this.spanObj[column.property][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }
    },
    //选择部门事件处理
    async selectDept(val) {
      this.searchCondition.dept_id = val.dept_id
      this.searchCondition.dept_name = val.dept_name
      await this.initList()
    },
    calcYearHalf() {
      let date = new Date();
      this.searchCondition.year = date.getFullYear() + ''

      let month = date.getMonth()
      if (month <= 5) {
        this.searchCondition.year_half = 'first_half';
      } else {
        this.searchCondition.year_half = 'second_half';
      }

    },
    /**
     * 初始化
     */
    async initDeptInfo() {
      //有录入权限-才能初始化
      if (this.userPermissions.indexOf('perf_dept_save') > -1) {
        await this.$api.initPerfDeptInfo({ search: this.searchCondition, data: this.dataDemo })
        this.$notify.success('初始化成功')
        await this.initList()
      }

    },
    async initList() {
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.dept_id && searchCondition.year && searchCondition.year_half) {
        let { list } = await this.$api.getPerfDeptInfoHalfYearData(searchCondition)
        if (list && list.length > 1) {
          this.$nextTick(() => {
            this.dataList = list
            this.rowspan()
          })
        } else {
          this.$confirm(`${ this.searchCondition.dept_name } ${ this.searchCondition.year }年 ${ this.searchCondition.year_half_alias } 数据未初始化，是否初始化固定目标?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.initDeptInfo()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消初始化'
            });
          });
        }

      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.year_half) {
        condition.year_half = this.searchCondition.year_half
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.dept_id) {
        condition.dept_id = this.searchCondition.dept_id
      }

      return condition
    },
    addBtn() {
      this.editObj = {}
      this.showDialog()
    },
    showDialog() {
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.dept_id && searchCondition.year && searchCondition.year_half) {
        this.dialogVisible = true
      } else
        this.$notify.error('请选择全部条件')
    },
    //右键菜单：设置是否月考核
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false;
        document.removeEventListener('click', this.closeMenu)
      }
    },
    //表格事件：行右键
    openMenu(row, column, e) {
      if (this.userPermissions.indexOf('perf_dept_save') > -1) {
        e.preventDefault();//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
        this.menuData = {
          visible: true,
          left: e.clientX - offsetLeft + 20,
          top: e.clientY + 20,
          currentRow: row,
          currentColumn: column
        }
        document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      }
    },
    // 选择菜单事件
    selectedMenu(menu) {
      switch (menu) {
        case 'addName':
          //添加自定义目标
          this.addBtn()
          break
        case 'editTerm':
        case 'editLowerW':
        case 'editUpperW':
        case 'editRealW':
          this.editObj = this.menuData.currentRow
          this.showDialog()
          break
        case 'addDsp':
        case 'editDsp':
        case 'editRealIntro':
          this.editObj = this.menuData.currentRow
          this.showDialog()
          break
        case 'delTerm':
          //删除目标大项
          this.delRow()
          break
      }
    },
    delRow() {
      let msg = `此操作将删除【目标大项-${ this.menuData.currentRow.target_term }】, 是否继续?`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async delInfo() {
      let info = await this.$api.delPerfDeptInfo(this.menuData.currentRow.info_id)
      console.log('info', info)
      if (info === 'ok') {
        this.$notify.success('删除成功')
        await this.initList()
      }

    },
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let name = `总监绩效-${ this.searchCondition.dept_name }-${ this.searchCondition.year }年${ this.searchCondition.year_half_alias }`
        let response = await this.$api.exportPerfDeptInfoHalfYearData(searchCondition)

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    showRowLog() {
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    },
  },
  created() {
    this.calcYearHalf()//计算半年度
    this.initList()
  }
}
</script>

<style scoped>
.searchCondition {
  margin-top: 5px;
  margin-bottom: 5px;
}

.op-link {
  margin-left: 10px;
}

</style>
